import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { PushContext } from "../context/push-wrapper";
import { signIn, useSession } from "next-auth/client";
import isAdminUser from "@/lib/utils/isAdminUser";
import { hasData, hasNoData } from "@/lib/utils/hasData";
import { chatmenu, config } from "@/lib/config";
import ChatForm from "./ChatForm";
import { ChatModal } from "./ChatModal";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import { JSONpreparrayforurl } from "@/lib/utils/JSONpreparrayforurl";
import { useInView } from "react-intersection-observer";
import useDraggableScroll from "use-draggable-scroll";
import toast from "react-simple-toasts";
import { updateJsonArray } from "../../lib/utils/handlers/chat";
import { IsJsonValid } from "@/lib/utils/isJsonValid";
import { followerFormat } from "@/lib/utils/followerFormat";
import { isBlocked, isBlockedRemaining } from "@/lib/utils/isBlocked";
import useChatScrollRef from "@/lib/utils/hooks/useChatScrollRef";
import { truncate } from "@/lib/utils/truncate";
import { Message } from "./Message";
import ChatUsersArrayVertical from "../profile/chat/chat-users-array-vertical";
import { uniqueMembers } from "@/lib/utils/uniqueMembers";
import { member_list } from "./member_list";
import ChatList from "./chatlist";
import SvgJsx from "../templateux/svg/svg-jsx";
import { startPlayer } from "@/lib/utils/startPlayer";
import ProfileContributorTag from "../profile/profile-contributor-tag";
import ChatMenuChatlist from "./chat-menu-chatlist";
import { flairObject } from "@/lib/utils/flairObject";
import Link from "next/link";
import { calculateUsers } from "@/lib/utils/calculateUsers";
import { getInterval } from "@/lib/utils/getInterval";
import { PlayerContext } from "../player/player-wrapper";
import StopWatch from "./stopwatch";
import { ifNull } from "@/lib/utils/ifNull";
import useSwipe from "@/lib/utils/hooks/useSwipe";
import ProfileMenuTipJarMobile from "../profile/item/profile-menu-tip-jar-mobile";
import { TribeContext } from "../context/tribe-wrapper";
import Loading from "../templateux/loading/loading";
import getMessage from "@/lib/utils/getMessage";
import { validTipJar } from "@/lib/utils/validTipJar";
import AvatarFlairClean from "../templateux/user/avatar-flair-clean";
import { textProcess } from "@/lib/utils/textprocess";

const ProfileChatDynamic = ({
  channel_id_chat_public,
  channel_id_chat_private,
  channel_id_listen,
  profiledata,
  userdata,
  navtype,
  helptype,
  nodata,
  //,ssr_data_chat
  router,
  show_users,
  set_show_users,
  show_chats,
  set_show_chats,
  closeModal,
  showReminder,
  set_showReminder,
  isSafari,
}) => {
  const { player_state, set_player_state } = useContext(PlayerContext);
  const ssr_data_tribe = useContext(TribeContext);

  const inputRef = useRef(null);
  const [isChatPopulated, set_isChatPopulated] = useState(false); //set to true since we load chat with ssr_data. only change this when we change the filter view to trigger swr.
  const [chatdata, set_chatdata] = useState<any[] | undefined>([]); //ssr_data_chat?.reverse()?.slice(75)
  const [chatdata_pause, set_chatdata_pause] = useState<any[] | undefined>([]); //ssr_data_chat?.reverse()?.slice(75)
  const [msg, set_msg] = useState({ text: "", emoji: 0, bol_private: 0 });
  const [members_chat, set_members_chat] = useState([]);
  const [members_listeners, set_members_listeners] = useState([]);
  const [loading_message, set_loading_message] = useState(null);
  const [session, loading] = useSession();
  const [single, set_single] = useState(null);
  const [multiple, set_multiple] = useState(null);
  const [cache, set_cache] = useState(null);
  const [isChatOpen, set_isChatOpen] = useState(false);
  const [report_queue, set_report_queue] = useState(profiledata?.report_queue);
  const [hidescrollbutton, set_hidescrollbutton] = useState(false);
  const [commenting, set_commenting] = useState(
    inputRef?.current?.value?.length > 0
  );
  const [changes, set_changes] = useState(false);
  const [shadow_text, set_shadow_text] = useState("");

  const timerRef = useRef(null);

  const [pause_chat, set_pause_chat] = useState(false);
  const pause_chatRef = useRef(pause_chat);
  pause_chatRef.current = pause_chat;

  //follow functions
  const [state_following_them, set_state_following_them] = useState(
    profiledata?.following_them > 0 ? true : false
  );
  const [state_following_them_status, set_state_following_them_status] =
    useState(
      hasData(profiledata?.following_them_status)
        ? profiledata?.following_them_status
        : false
    );

  useEffect(() => {
    set_loading_message(getMessage("loading", ssr_data_tribe));
  }, [ssr_data_tribe]);

  useEffect(() => {
    if (profiledata && !loading && userdata) {
      set_state_following_them(
        hasData(profiledata?.following_them)
          ? parseInt(profiledata?.following_them) > 0
          : false
      );
      set_state_following_them_status(
        hasData(profiledata?.following_them_status)
          ? parseInt(profiledata?.following_them_status)
          : 1
      );
    }
  }, [profiledata, loading, userdata]);

  //timer functions
  const [time, set_time] = useState(0);

  const timeRef = useRef(time);
  timeRef.current = time;

  const [modal_panel, set_modal_panel] = useState({
    data: "[]",
    id: "users",
    type: "multiple",
    length: 0,
  });

  const [modal_length, set_modal_length] = useState(1);
  const [owner_id, set_owner_id] = useState(profiledata?.id);
  const [isAdmin, set_isAdmin] = useState(false);
  const [isApproved, set_isApproved] = useState(false);
  const [isOwner, set_isOwner] = useState(false);

  // CHAT FILTERS
  const [filter, set_filter] = useState(
    hasNoData(router?.query?.filter, "all", ["all", "report", "dm", "save"])
  );
  const filterRef = useRef(filter);
  filterRef.current = filter;
  const [filtercache, set_filtercache] = useState(+new Date());

  //RAID
  const [raid_user_name, set_raid_user_name] = useState(null);

  const refscroll = useChatScrollRef(chatdata, false);

  const this_userdataRef = useRef(userdata);
  this_userdataRef.current = userdata;

  const profiledataRef = useRef(profiledata);
  profiledataRef.current = profiledata;

  //scrollable ref
  //const [show_bottomscroll,set_show_bottomscroll] = useState(false)
  const ref = useRef(null);
  const scrollRef = useRef(null);
  const [bottomRef, isOnScreen] = useInView({
    /* Optional options */
    threshold: 0,
    initialInView: true,
    //onChange:(inView)=>{console.log("view changed",inView)}
  });
  const isOnScreenRef = useRef(isOnScreen);
  isOnScreenRef.current = isOnScreen;

  const swipeHandlers = useSwipe({
    onSwipedRight: () => {
      if (inputRef.current.value.length == 0) {
        //only activate swiping when there's no text on screen so we dont interfere with text selection during chatting
        // user menu is already open
        if (show_users) {
          //close users
          set_show_users(false);
        } else {
          // open Chat menu
          toggleChats(
            windowinnerwidth -
              (show_users ? config.chat.windowoffset.panelwidth : 0) -
              (show_chats ? config.chat.windowoffset.panelwidth : 0),
            show_chats
          );
        }
      }
    },
    onSwipedLeft: () => {
      if (inputRef.current.value.length == 0) {
        //only activate swiping when there's no text on screen so we dont interfere with text selection during chatting
        // Chat menu is already open
        if (show_chats) {
          //close chats
          set_show_chats(false);
        } else {
          // open Users menu
          toggleUsers(
            windowinnerwidth -
              (show_users ? config.chat.windowoffset.panelwidth : 0) -
              (show_chats ? config.chat.windowoffset.panelwidth : 0),
            show_users
          );
        }
      }
    },
  });

  const onChatResume = async () => {
    set_chatdata_pause((prev_pause) => {
      prev_pause.map((msg) => {
        //move the pause data over
        set_chatdata((prev) => [
          ...prev?.slice(config.chat.displaymessages * -1),
          msg,
        ]);
      });
      return []; //then reset it
    });
    scrollToBottom(scrollRef, "smooth", 0);
  };

  useEffect(() => {
    if (!isOnScreenRef.current) {
      // || is_typing) { //disabling this per now at user's requests...
      setTimeout(() => {
        if (!isOnScreenRef.current) set_pause_chat(true);
      }, 100);
      //set_pause_chat(true)
    } else {
      onChatResume();
      set_pause_chat(false);
    }
  }, [
    isOnScreenRef.current,
    //,is_typing
  ]);

  // INITIAL MSG DATA
  const {
    data: chatarraydata,
    loading: chatarraydataisLoading,
    error: chatarraydataisError,
    boundMutate: itemsMutate,
  } = useSWRWrapper(
    profiledata?.id?.toString(), //id
    `/api/private/chat/select?id=${
      profiledata?.user_name
    }&filter=${filter}&cache=${filtercache}${
      hasData(router?.query?.raid) ? `&raid=${router?.query?.raid}` : ``
    }`, //key
    true, //retry?
    config.max_retries, //max number of retries
    config.retry_interval, //retry interval
    0 //config.chat.messages_interval //refreshinterval (0=none)
  );

  useEffect(() => {
    if (
      !chatarraydataisLoading &&
      !chatarraydataisError &&
      chatarraydata &&
      !isChatPopulated &&
      userdata
    ) {
      set_isChatPopulated(true);

      //add a check for whether there is ignored user content here XXX........
      let this_ignore_json = userdata?.ignore_json;
      if (this_ignore_json?.length > 0 && this_ignore_json !== "[]") {
        const ignore_filter = JSON.parse(userdata?.ignore_json);
        //run a filter comparison against all messages and stripped out any ignoreed users
        set_chatdata(
          chatarraydata
            ?.filter(({ id: id1, target_id: ti1 }) =>
              ignore_filter.some(({ id: id2 }) => id2 !== id1 && id2 !== ti1)
            )
            ?.reverse()
        );
      } else {
        set_chatdata(chatarraydata?.reverse());
      }

      scrollToBottom(scrollRef, "auto", 200);
    }
  }, [
    chatarraydataisLoading,
    chatarraydataisError,
    chatarraydata,
    isChatPopulated,
    userdata,
  ]);

  //CHAT SINGLE USER DATA
  const {
    data: chatsingleuserdata,
    loading: chatsingleuserdataisLoading,
    error: chatsingleuserdataisError,
    boundMutate: itemsMutateSingle,
  } = useSWRWrapper(
    hasData(single) ? single : null, //id
    `/api/private/profile/select?id=${single}&cache=${cache}`, //key
    true, //retry?
    config.max_retries, //max number of retries
    config.retry_interval, //retry interval
    0 //refreshinterval (0=none)
  );

  //CHAT USER DATA BY LIKES (CALLED DYNAMICALLY ON MOUSEOVER OF USER LIKES)
  const {
    data: chatmultipleuserdata,
    loading: chatmultipleuserdataisLoading,
    error: chatmultipleuserdataisError,
    boundMutate: itemsMutateMultiple,
  } = useSWRWrapper(
    hasData(multiple) ? multiple : null, //id
    `/api/private/user/select-multiple?id=${
      profiledata?.id
    }&arr=${JSONpreparrayforurl(multiple)}&cache=${cache}`, //key
    false, //retry?
    config.max_retries, //max number of retries
    config.retry_interval, //retry interval
    0 //refreshinterval (0=none)
  );

  useEffect(() => {
    if (profiledata) {
      let isUserAdmin = isAdminUser(
        profiledata?.id, //user_id
        profiledata?.bol_staff_help, //bol_staff_help
        profiledata?.staff_json, //staff_json
        profiledata?.bol_admin_help, //bol_admin_help
        userdata?.id?.toString(), //my_id
        userdata?.bol_admin?.toString(), //global_admin
        "chat"
      );

      set_isAdmin(isUserAdmin);

      let approve_json_object = flairObject(profiledata?.approve_json);

      //check to see if user matches approval list
      if (
        isUserAdmin ||
        (approve_json_object?.length > 0 &&
          approve_json_object?.filter(
            (f) =>
              f?.name?.toString()?.toLowerCase() ==
                userdata?.user_name?.toString()?.toLowerCase() ||
              f?.id?.toString() == userdata?.id?.toString()
          )?.length > 0)
      ) {
        set_isApproved(true);
      }

      set_isOwner(userdata?.id?.toString() === profiledata?.id?.toString);
    }

    return () => {};
  }, [profiledata, userdata?.id, userdata?.bol_admin]);

  const [isUserBlocked, set_isUserBlocked] = useState(
    isBlocked(
      profiledata?.block_json,
      profiledata?.timestamp,
      userdata?.user_name
    )
  );
  useEffect(() => {
    set_isUserBlocked(
      isBlocked(
        profiledata?.block_json,
        profiledata?.timestamp,
        userdata?.user_name
      )
    );
  }, [profiledata?.block_json, profiledata?.timestamp, userdata?.id]);

  useEffect(() => {
    //open the chat menu to show the tip jar if it's closed and the campaign ends.
    if (
      profiledata?.bol_tip_jar?.toString() == "1" &&
      profiledata?.stream_status?.toString() == "0" &&
      getInterval(profiledata?.stream_end_date, profiledata?.timestamp) <
        config.tip_jar.shake_interval &&
      getInterval(profiledata?.stream_end_date, profiledata?.timestamp) !== -1
    ) {
      set_show_users(true);
    }
  }, [profiledata?.stream_status]);

  // useEffect(()=>{
  //   console.log("ignoring data",this_userdataRef?.current?.ignore_json, userdata?.ignore_json,IsJsonValid(userdata?.ignore_json))
  //   set_ignore_json(IsJsonValid(userdata?.ignore_json) ? JSON.parse(userdata?.ignore_json) : [])
  // },[
  //   userdata?.ignore_json
  // ])

  const { onMouseDown } = useDraggableScroll(ref, { direction: "vertical" });

  const initiateScroll = async (ref, behavior) => {
    //temporarily hide the scroll button while we scroll to the bottom

    await ref?.current?.scrollIntoView({
      behavior: behavior,
      block: "end",
      inline: "end",
    });
  };

  const scrollToBottom = async (ref, behavior, delay) => {
    if (ref?.current) {
      await set_hidescrollbutton(true);
      await setTimeout(() => initiateScroll(ref, behavior), delay);
      await set_hidescrollbutton(false);
    }
  };

  const set_extra = async (e, user_name) => {
    await set_filtercache(+new Date());

    set_isChatPopulated(false);

    let extra_private = 0; //all
    if (e == "dm") extra_private = 1; //dm
    if (e == "save") extra_private = 1; //saved
    if (e == "report") {
      //report
      extra_private = 1;

      //clear reports
      const res = await fetch(
        `/api/private/user/update-report-queue?user=${user_name}`
      );
      const json = await res.json();

      if (json) {
        const { action } = json[0][0];
        if (action?.toString() == "429") {
          toast(`Slow down! You move too fast. 🎼♩♬♫🎶🎵`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        }
      }
    }

    set_msg({ ...msg, bol_private: extra_private });
  };

  const onMessage = (channel, rawmessage) => {
    let channel_string = `${ssr_data_tribe?.brand_id}-${config.websockets.chat.public}`;
    var channel_owner_id = channel?.name.replace(channel_string, "");
    let this_ignore_json = IsJsonValid(this_userdataRef?.current?.ignore_json)
      ? JSON.parse(this_userdataRef?.current?.ignore_json)
      : [];

    let message = rawmessage;

    //see if we can find the username in the ignored list
    let is_ignored =
      this_ignore_json?.find(
        (i) =>
          [
            message?.user_name?.toString()?.toLowerCase(),
            message?.target_name?.toString()?.toLowerCase(),
          ].indexOf(i?.name?.toString()?.toLowerCase()) > -1
      ) !== undefined &&
      this_ignore_json?.find(
        (i) => [message?.id?.toString()].indexOf(i?.id?.toString()) > -1
      );

    //messages
    if (["message", "bot", "server"].indexOf(message?.type?.toString()) > -1) {
      //set_chatdata(prev => [...prev?.slice(-199), message]);

      //logic to make sure user is allowed to see the message...
      if (
        message?.owner_id?.toString() ==
          profiledataRef?.current?.id.toString() && // are we sending this to the right channel?
        (message?.bol_private?.toString() == "0" || //open to all
          (message?.bol_private?.toString() == "1" && //private
            (message?.target_id?.toString() ==
              this_userdataRef?.current?.id?.toString() || // user is target
              (message?.type?.toString() == "message" && //dont let bot announcements be seen by non-logged in users
                message?.id?.toString() ==
                  this_userdataRef?.current?.id?.toString())))) //user is sender
      ) {
        //can we post the message?
        if (
          (chatdata || []).indexOf(
            (e) => e?.chat_id?.toString() == message?.chat_id?.toString()
          ) == -1 && // no duplicates
          !is_ignored //no ignored users
        ) {
          //only update the visible array with subscription data in ALL / DM's (not Saved or Reports)
          if (["all", "dm"].indexOf(filterRef.current) > -1) {
            if (pause_chatRef.current) {
              set_chatdata_pause((prev) => [
                ...prev?.slice(config.chat.displaymessages * -1),
                { ...message, initial_time: timeRef.current || 0 },
              ]);
            } else {
              set_chatdata((prev) => [
                ...prev?.slice(config.chat.displaymessages * -1),
                { ...message, initial_time: timeRef.current || 0 },
              ]);
            }

            //post the message, and slice off the oldest message (leaving buffer room if we're not at the bottom)
            // set_chatdata((prev) => uniq([message
            // , ...prev]));

            if (
              !pause_chatRef.current || //all: if at the bottom, keep scrolling
              message?.id?.toString() ==
                this_userdataRef?.current?.id?.toString() //author: always scroll after posting
            ) {
              scrollToBottom(scrollRef, "auto", 200);
            }
          }
        }
      }

      //START A CHAT RAID]
      if (
        message?.message_original?.substr(0, 7) == "/raid @" &&
        message?.id.toString() == message?.owner_id.toString() && //profiledataRef?.current?.id.toString()
        this_userdataRef?.current?.bol_allow_raid?.toString() == "1"
      ) {
        //only an owner can trigger a raid

        let raid_user_name = message?.message_original?.substr(
          7,
          message?.message_original?.length
        );
        //let raid_id = message?.target_id

        window.location.href = `/${raid_user_name}/chat`;
      }
    }

    //deletes
    if (message?.type == "delete") {
      set_chatdata((prev) =>
        prev.filter(
          (x) => x?.chat_id?.toString() !== message?.chat_id?.toString()
        )
      );
      set_chatdata_pause((prev) =>
        prev.filter(
          (x) => x?.chat_id?.toString() !== message?.chat_id?.toString()
        )
      );
    }

    //likes
    if (message?.type == "like") {
      let bottom_fix = isOnScreenRef.current;

      set_chatdata((prev) =>
        prev.map((x) =>
          x?.chat_id?.toString() == message?.chat_id?.toString()
            ? {
                ...x,
                likes: x?.likes + message?.action,
                likes_json: updateJsonArray(
                  message?.action,
                  x?.likes_json,
                  message?.user_id
                ),
              }
            : x
        )
      );
      set_chatdata_pause((prev) =>
        prev.map((x) =>
          x?.chat_id?.toString() == message?.chat_id?.toString()
            ? {
                ...x,
                likes: x?.likes + message?.action,
                likes_json: updateJsonArray(
                  message?.action,
                  x?.likes_json,
                  message?.user_id
                ),
              }
            : x
        )
      );

      if (bottom_fix) scrollToBottom(scrollRef, "auto", 0);
    }

    //saves
    if (message?.type == "save") {
      set_chatdata((prev) =>
        prev.map((x) =>
          x?.chat_id?.toString() == message?.chat_id?.toString()
            ? {
                ...x,
                saves: x?.saves + message?.action,
                saves_json: updateJsonArray(
                  message?.action,
                  x?.saves_json,
                  message?.user_id
                ),
              }
            : x
        )
      );
      set_chatdata_pause((prev) =>
        prev.map((x) =>
          x?.chat_id?.toString() == message?.chat_id?.toString()
            ? {
                ...x,
                saves: x?.saves + message?.action,
                saves_json: updateJsonArray(
                  message?.action,
                  x?.saves_json,
                  message?.user_id
                ),
              }
            : x
        )
      );
    }

    //report
    if (message?.type == "report") {
      set_chatdata((prev) =>
        prev.map((x) =>
          x?.chat_id?.toString() == message?.chat_id?.toString()
            ? {
                ...x,
                reports: x?.reports + message?.action,
                reports_json: updateJsonArray(
                  message?.action,
                  x?.reports_json,
                  message?.user_id
                ),
              }
            : x
        )
      );
      set_chatdata_pause((prev) =>
        prev.map((x) =>
          x?.chat_id?.toString() == message?.chat_id?.toString()
            ? {
                ...x,
                reports: x?.reports + message?.action,
                reports_json: updateJsonArray(
                  message?.action,
                  x?.reports_json,
                  message?.user_id
                ),
              }
            : x
        )
      );
    }

    //REPORTS CLEARED
    if (message?.type == "clear" && message?.filter == "report")
      set_report_queue(0);

    //BROADCAST ENDED
    if (message?.type == "end") {
      set_player_state((prev) => {
        if (
          prev?.broadcast_status?.toString() == "1" &&
          prev?.player_type == "live" &&
          prev?.content_id?.toString() == message?.content_id?.toString()
        ) {
          //console.log("ended broadcast")
          return { ...prev, broadcast_status: "0", isPlaying: false };
        } else {
          // console.log(player_state?.broadcast_status,player_state?.player_type,player_state?.content_id?.toString(),
          // message?.content_id?.toString())
          return { ...prev };
        }
      });
    }
  };

  const onMount = async (
    channel,
    channel_id,
    userdata,
    profiledata,
    bol_subscribe,
    bol_enter,
    bol_listen,
    set_members
  ) => {
    //are we listening to messages in this channel?
    if (bol_listen) {
      channelListen(channel, userdata);
    }

    //are we subscribing to events in this channel
    if (bol_subscribe) {
      //get the initial list
      channel.presence.get(function (err, data) {
        set_members(data);
      });

      channel.presence.subscribe("enter", function (member) {
        channel.presence.get(function (err, data) {
          set_members(data);
        });
      });

      channel.presence.subscribe("update", function (member) {
        channel.presence.get(function (err, data) {
          set_members(data);
        });
      });

      channel.presence.subscribe("leave", function (member) {
        channel.presence.get(function (err, data) {
          set_members(data);
        });
      });
    }

    //are we entering presence in this location (or do we do it elsewhere, as in the listening event)
    if (bol_enter) {
      var custom_flair = "";
      let flair_json_object = await flairObject(profiledata?.flair_json);

      if (
        profiledata?.bol_flair?.toString() == "1" &&
        profiledata?.contributor_level > 0 &&
        flair_json_object?.length > 0 &&
        flair_json_object?.filter(
          (f) =>
            f?.name?.toString()?.toLowerCase() ==
            userdata?.user_name?.toString()?.toLowerCase()
        )?.length > 0
      ) {
        custom_flair = flair_json_object
          ?.filter(
            (f) =>
              f?.name?.toString()?.toLowerCase() ==
              userdata?.user_name?.toString()?.toLowerCase()
          )[0]
          ?.tag?.toString();
      }

      channel.presence.enter({
        id: userdata?.id,
        user_name: userdata?.user_name,
        avatar_url: userdata?.avatar_url,
        avatar_url_sfw: userdata?.avatar_url_sfw,
        avatar_crdate: userdata?.avatar_crdate,
        bol_nsfw: userdata?.bol_nsfw,
        contributor_level: userdata?.contributor_level,
        //,verified: userdata?.verified
        custom_flair: custom_flair,
        listen: false,
        chat: false,
      });
    }
  };

  // const onMountPrivate = (channel,userdata,profiledata) => {

  //   channelListen(channel,userdata);

  // }

  const channelListen = (channel, userdata) => {
    channel.subscribe((rawmsg) => {
      let msg = rawmsg?.data;

      //update the existing message since this is our own
      if (
        msg?.type == "message" && //ensures bot messages are not captured here by false positive of 0 == 0
        msg?.id?.toString() == userdata?.id?.toString() &&
        msg?.owner_id?.toString() == owner_id?.toString() //ensures we only receive the message in the correct channel (to avoid misposting private messages)
      ) {
        //replace the existing message in the array using placeholder id

        set_chatdata((prev) => {
          //found the placeholder, so this message originated on this client. Let's replace it with the processed message using the placeholder id.
          if (
            prev.some(
              (o) =>
                o?.placeholder_id?.toString() == msg?.placeholder_id?.toString()
            )
          ) {
            // console.log("msg inside placeholder logic",msg)
            return prev.map(
              (obj) =>
                [
                  {
                    ...msg,
                    target_id: msg?.target_id,
                    initial_time: obj[`initial_time`] || 0,
                  },
                ] //override the initial time to keep the chat timers accurate
                  .find(
                    (o) =>
                      o?.placeholder_id?.toString() ==
                      obj?.placeholder_id?.toString()
                  ) || obj
            );
            //we didnt find the placeholder, so this scenario is the same user logged into multiple devices
          } else {
            return [
              ...prev?.slice(config.chat.displaymessages * -1),
              { ...msg, initial_time: timeRef.current || 0 },
            ];
          }
        });

        if (isOnScreenRef.current) scrollToBottom(scrollRef, "auto", 200);
      } else {
        //for other users posts, process all, if it's unique. <- TODO

        onMessage(channel, msg);
      }
    });
  };

  const onUnmount = (channel, bol_subscribe, bol_leave) => {
    if (bol_leave) {
      channel.presence.leave();
    }

    if (bol_subscribe) {
      channel.unsubscribe();
      channel.presence.unsubscribe();
    }
  };

  const ably = useContext(PushContext);

  const channel_listen = ably?.channels?.get(
    `${ssr_data_tribe?.brand_id}-${config.websockets.listen}${profiledata?.id}`
  );

  const [subscribed, set_subscribed] = useState(false);

  useEffect(() => {
    if (userdata && profiledata && !userdata?.isLoading && !subscribed) {
      onMount(
        ably.channels.get(channel_id_chat_public),
        channel_id_chat_public,
        userdata,
        profiledata,
        true,
        true,
        true,
        set_members_chat
      );
      onMount(
        ably.channels.get(channel_id_chat_private),
        channel_id_chat_private,
        userdata,
        profiledata,
        false,
        false,
        true,
        () => {}
      );
      onMount(
        ably.channels.get(channel_id_listen),
        channel_id_listen,
        userdata,
        profiledata,
        true,
        false,
        false,
        set_members_listeners
      );
      set_subscribed(true);
    }
  }, [userdata, profiledata]);

  useEffect(() => {
    return () => {
      onUnmount(ably.channels.get(channel_id_chat_public), true, true);
      onUnmount(ably.channels.get(channel_id_chat_private), true, true);
      onUnmount(ably.channels.get(channel_id_listen), true, false);
    };
  }, []);

  const placeholder_data = (placeholder_id, message, placeholder) => {
    let thisdata = {
      clientId: undefined,
      connectionId: undefined,
      connectionKey: undefined,
      encoding: null,
      extras: undefined,
      id: undefined,
      name: "data",
      size: undefined,
      timestamp: +new Date(),
      data: {
        type: "message",
        chat_id: 0,
        placeholder_id: placeholder_id,
        placeholder: placeholder,
        message: message.text,
        message_unfiltered: message.text,
        message_original: message.text,
        message_original_raw: message.text,
        bol_filtered: 0,
        likes: 0,
        likes_json: "[]",
        crdate: new Date()?.toJSON()?.toString(),
        emoji: message.emoji,
        id: userdata?.id,
        user_name: userdata?.user_name,
        avatar_url: userdata?.avatar_url,
        bol_nsfw: userdata?.bol_nsfw,
        avatar_url_sfw: userdata?.avatar_url_sfw,
        followers: 0,
        verified: userdata?.verified,
        contribution_date: userdata?.contribution_date,
        reports: 0,
        reports_json: "[]",
        saves: 0,
        saves_json: "[]",
        bol_real_data: 1,
        timestamp: new Date()?.toJSON()?.toString(),
        bol_private: message?.bol_private,
        target_id: message?.target_id || 0,
        contributor_level: userdata?.contributor_level,
        owner_id: profiledata?.id,
        initial_time: time,
      },
    };
    return thisdata;
  };

  const sendmsg = async (
    e,
    channel_id,
    type,
    message: any,
    image_json: any
  ) => {
    //handle form submission default states here
    if (e) e.preventDefault();

    //handle common flood scenarios here:
    if (!hasData(message.text)) return;

    const channel = ably.channels.get(channel_id);

    const placeholder_id = +new Date();

    //add a placeholder to the messages array for a more instantaneous feel
    if (type == "message") {
      await onMessage(
        channel,
        placeholder_data(placeholder_id, message, 1)?.data
      );
      await scrollToBottom(scrollRef, "auto", 0);
    }

    sendmsgdata(channel_id, type, message, image_json, placeholder_id);

    //reset textbox since this is a form submission
    set_msg({ ...msg, text: "" });
  };

  const sendmsgdata = async (
    channel_id,
    type,
    message,
    image_json,
    placeholder_id = 0
  ) => {
    // client logic (disabled)
    // channel.publish({name:"chat-message", data: results?.message});

    // server logic
    fetch(`/api/private/chat/insert`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: userdata?.id,
        tribe_id: ssr_data_tribe?.tribe_id,
        brand_id: ssr_data_tribe?.brand_id,
        channel_id,
        owner_id: profiledata?.id,
        type,
        message: JSON.stringify(
          truncate(message, config.chat.maxmessagelength, "...")
        ),
        image_json: JSON.stringify(image_json),
        chatter_name: userdata?.user_name,
        placeholder_id: placeholder_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          const { outcome, msg } = json;

          //Use the results to see if a message needs to be displayed
          if (outcome == 1) return; //all is good and happy in the world
          if (outcome == -1)
            toast(`The chat is read-only (except for the host) right now.`, {
              time: 3000,
              className: "",
              clickable: true,
              clickClosable: false,
            });
          if (outcome == -2)
            toast(`Slow down! You move too fast. 🎼♩♬♫🎶🎵`, {
              time: 3000,
              className: "",
              clickable: true,
              clickClosable: false,
            });
          if (outcome == -3)
            toast(
              `Your account is not allowed to post in this chat. Please email ${ssr_data_tribe?.email_support} to correct this.`,
              {
                time: 3000,
                className: "",
                clickable: true,
                clickClosable: false,
              }
            );
          if (outcome == -4)
            toast(
              `Take a breather. You may not post in chat for ${config.chat.timeout[msg].name}.`,
              {
                time: 3000,
                className: "",
                clickable: true,
                clickClosable: false,
              }
            );
          if (outcome == -5)
            toast(
              `You need an account to post in chat. If you are logged in, please try refreshing. Contact ${ssr_data_tribe?.email_support} if refreshing does not help.`,
              {
                time: 3000,
                className: "",
                clickable: true,
                clickClosable: false,
              }
            );
          if (outcome == -6)
            toast(`No chat data was sent to the server...`, {
              time: 3000,
              className: "",
              clickable: true,
              clickClosable: false,
            });
          if (outcome == -7)
            toast(
              `Take a 15-second breather... Your account is sending unusual amounts of data to us in a short time frame.`,
              {
                time: 3000,
                className: "",
                clickable: true,
                clickClosable: false,
              }
            );
          if (outcome == -8)
            toast(
              `Chat is currently only open for approved users to post comments.`,
              {
                time: 3000,
                className: "",
                clickable: true,
                clickClosable: false,
              }
            );
          if (outcome == -9)
            toast(`Chat is currently disabled by the host.`, {
              time: 3000,
              className: "",
              clickable: true,
              clickClosable: false,
            });
          if (outcome == -10)
            toast(`Chat is currently disabled by the host.`, {
              time: 3000,
              className: "",
              clickable: true,
              clickClosable: false,
            });

          //we failed to post the message to the database somehow...so we use placeholder_data to show an undelivered message
          set_chatdata((prev) =>
            prev.map(
              (obj) =>
                [
                  {
                    ...placeholder_data(placeholder_id, message, -1)?.data,
                    initial_time: obj?.initial_time || 0,
                  },
                ].find(
                  (o) =>
                    o?.placeholder_id?.toString() ==
                    obj?.placeholder_id?.toString()
                ) || obj
            )
          );
          //scrollToBottom(scrollRef,'smooth',200);
        }
      });
  };

  const openModal = (n_data, n_type, n_id, n_length, n_title) => {
    if (n_type == "single") set_single(n_data);
    if (n_type == "multiple") set_multiple(n_data);
    set_cache(+new Date());
    set_modal_length(n_length);
    set_modal_panel({
      data: n_data,
      type: n_type,
      id: n_id,
      length: n_length,
    });
    set_isChatOpen(true);
  };

  const openModalChooser = (type, data = "") => {
    set_modal_panel({
      data: data,
      type: type,
      id: type,
      length: 1,
    });

    set_isChatOpen(true);
  };

  const closeChatModal = (override = false) => {
    if (!override && changes) {
      if (
        !confirm(
          "You currently have unsaved work. Are you sure you want to close this panel?"
        )
      )
        return;
    }
    set_isChatOpen(false);
  };

  const [show_chat_rules, set_show_chat_rules] = useState(0);
  useEffect(() => {
    if (
      show_chat_rules == 0 &&
      raid_user_name !== profiledata?.user_name?.toLowerCase()?.toString() &&
      profiledata?.bol_chat_rules?.toString() == "1" &&
      hasData(profiledata?.chat_rules)
    ) {
      let localcookie = localStorage.getItem(`chat-${owner_id}`);

      set_show_chat_rules(1);
      if (
        !hasData(localcookie) ||
        localcookie < profiledata?.chat_rules_timestamp
      ) {
        openModalChooser("welcome");
      }
    }
  }, [
    show_chat_rules,
    profiledata,
    owner_id,
    raid_user_name,
    openModalChooser,
  ]);

  ///const [placeholder,set_placeholder] = useState(true)

  //WINDOW RESIZE
  const [offset_height, set_offset_height] = useState(
    config.chat.windowoffset.sm
  );
  const [offset_width, set_offset_width] = useState(
    config.chat.windowoffset.width.sm
  );

  const [windowinnerheight, set_windowinnerheight] = useState(
    typeof window !== "undefined" ? window.innerHeight - offset_height : 600
  );
  const [windowinnerwidth, set_windowinnerwidth] = useState(
    typeof window !== "undefined" ? window.innerWidth - offset_width : 600
  );
  const [chat_width, set_chat_width] = useState(
    windowinnerwidth -
      (show_users ? config.chat.windowoffset.panelwidth : 0) -
      (show_chats ? config.chat.windowoffset.panelwidth : 0)
  );

  const resize = () => {
    let thisoffset_height = offset_height;
    thisoffset_height =
      window.innerWidth >= 1024
        ? config.chat.windowoffset.lg
        : config.chat.windowoffset.sm;

    thisoffset_height =
      thisoffset_height - config.chat.windowoffset.offline_extra;

    set_offset_height(thisoffset_height);

    let thisoffset_width = config.chat.windowoffset.width.sm;
    thisoffset_width =
      window.innerWidth >= 480
        ? config.chat.windowoffset.width.lg
        : config.chat.windowoffset.width.sm;

    set_offset_width(thisoffset_width);

    if (typeof window !== "undefined") {
      let newheight = Math.max(
        window.innerHeight - thisoffset_height,
        config.chat.minchatheight
      );
      let newwidth = window.innerWidth - thisoffset_width;

      set_windowinnerheight(newheight);
      set_windowinnerwidth(newwidth);
      set_chat_width(
        newwidth -
          (show_users ? config.chat.windowoffset.panelwidth : 0) -
          (show_chats ? config.chat.windowoffset.panelwidth : 0)
      );
    }
  };

  const onResize = useCallback(() => {
    resize();
  }, [resize]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", onResize);
      window.addEventListener("orientationchange", onResize);
    }

    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("orientationchange", onResize);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      onResize();
    }

    return () => {};
  }, [offset_height]);

  function startRaid(e) {
    e.preventDefault();
    let raidee = prompt(
      "What's the username you want to raid?",
      ssr_data_tribe?.brand_author
    );
    if (hasData(raidee)) {
      sendmsg(
        e,
        channel_id_chat_public,
        "message",
        { text: `/raid @${raidee}`, bol_private: 0, emoji: 0 },
        ""
      );
    }
  }

  function saveTimestamp(e, ts) {
    e.preventDefault();
    set_isChatOpen(true);
    openModalChooser("timestamp", ts);
  }

  const CloseButton = () => {
    return (
      <button
        type="button"
        className="inline-flex justify-center px-2 py-1 text-xs sm:text-md font-medium text-white bg-blue-500 hover:bg-blue-400  border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
        onClick={() => closeChatModal()}
      >
        Close
      </button>
    );
  };

  const chatters = useRef(
    hasData(uniqueMembers(members_chat)?.length)
      ? uniqueMembers(members_chat)?.length
      : 0
  );
  const listeners = useRef(
    hasData(uniqueMembers(members_listeners)?.length)
      ? uniqueMembers(members_listeners)?.length
      : 0
  );
  chatters.current = hasData(uniqueMembers(members_chat)?.length)
    ? uniqueMembers(members_chat)?.length
    : 0;
  listeners.current = hasData(uniqueMembers(members_listeners)?.length)
    ? uniqueMembers(members_listeners)?.length
    : 0;

  //chat was disabled
  if (profiledataRef?.current?.bol_show_chat == "0") {
    return (
      <>
        <div
          className={`my-5 px-5 flex flex-col items-center content-center justify-center mt-20
                                  text-white
                              `}
        >
          <div className="font-bold sm:text-lg">
            Chat has been disabled by {profiledataRef?.current?.user_name}.
          </div>
          <div>
            <Link
              href={`/${profiledataRef?.current?.user_name}`}
              className="underline hover:no-underline"
            >
              Return to their profile
            </Link>
          </div>
          <div>
            <Link href={`/`} className="underline hover:no-underline">
              or return to the homepage
            </Link>
          </div>
        </div>
      </>
    );
  }

  const chatlists = (
    <>
      {" "}
      <div
        className="flex flex-col w-full overflow-y-auto overflow-x-hidden"
        style={{
          height: windowinnerheight + 48,
        }}
        ref={ref}
        onMouseDown={onMouseDown}
        onDragStart={onMouseDown}
        onDrag={onMouseDown}
      >
        <div className="flex-1">
          <ChatList
            filter={filter}
            set_filter={set_filter}
            set_extra={set_extra}
            submenuarray={chatmenu.menuarray}
            className={""}
            isAdmin={isAdmin}
            isUser={userdata?.id > 0}
            report_queue={report_queue}
            user_name={profiledata?.user_name}
            set_chatdata={set_chatdata}
            navtitle={`${profiledata?.user_name} | ${navtype} `}
            ssr_data_tribe={ssr_data_tribe}
            permaurl={`/${profiledata?.user_name}/chat`}
          />
        </div>
        <div className="w-full py-1">
          <ChatMenuChatlist
            openModalWelcome={() => openModalChooser("welcome")}
            openModalAbout={() => openModalChooser("about")}
            openModalStats={() => openModalChooser("stats")}
            show_stats={true}
            startRaid={(e) => startRaid(e)}
            saveTimestamp={(e) =>
              saveTimestamp(e, ifNull(timerRef.current?.innerHTML, ""))
            }
            isAdmin={isAdmin}
            isOwner={isOwner}
            isLive={profiledata?.stream_status?.toString() == "1"}
            router={router}
            owner_name={profiledata?.user_name}
            show_welcome={
              profiledata?.bol_chat_rules?.toString() == "1" &&
              hasData(profiledata?.chat_rules)
            }
            data-tip={"Chat Menu Options"}
            data-for={`chatusers`}
            pause_chat={pause_chat}
            showReminder={showReminder}
            set_pause_chat={() => {
              if (pause_chat) {
                set_pause_chat(false);
                onChatResume();
              } else {
                set_pause_chat(true);
              }
            }}
            closeModal={closeModal}
          />
        </div>
      </div>
    </>
  );

  const updateLocalStorage = async (n) => {
    if (hasData(localStorage.getItem(n)) && localStorage.getItem(n) == "1") {
      localStorage.setItem(n, "0");
    } else {
      localStorage.setItem(n, "1");
    }
  };

  const toggleChats = async (width, is_open) => {
    set_show_chats((prev) => !prev);
    let panel_width = config.chat.windowoffset.panelwidth * (is_open ? 1 : -1);
    let new_width = width + panel_width;
    if (new_width < config.chat.windowoffset.minmessagewidth) {
      set_show_users(false);
      updateLocalStorage(`chat-users`);
    }
    updateLocalStorage(`chat-chatlists`);
  };

  const toggleUsers = async (width, is_open) => {
    set_show_users((prev) => !prev);
    let panel_width = config.chat.windowoffset.panelwidth * (is_open ? 1 : -1);
    let new_width = width + panel_width;
    if (new_width < config.chat.windowoffset.minmessagewidth) {
      set_show_chats(false);
      updateLocalStorage(`chat-chatlists`);
    }
    updateLocalStorage(`chat-users`);
  };

  return (
    <>
      <div {...swipeHandlers}>
        {/* HEADER */}

        <div
          className={`w-full flex items-center content-center py-2 px-2 shadow-md 
                                  ${
                                    showReminder
                                      ? "bg-red-600 animate-pulse"
                                      : "bg-gray-800 "
                                  }
                                  border-b border-gray-600
                                  `}
        >
          {/*  TRIGGER CHAT LISTS */}
          <div
            role="button"
            tabIndex={0}
            title="Show Chat Options"
            className={` block cursor-pointer mr-2 border  p-1.5 rounded-md relative
                                      ${
                                        showReminder
                                          ? show_chats
                                            ? " bg-red-700 text-white border border-red-700 hover:border-white "
                                            : " text-white border-red-700 hover:border-white hover:bg-red-500 "
                                          : show_chats
                                          ? " text-white  bg-gray-600   hover:bg-gray-400 border-gray-600"
                                          : "   text-gray-400   hover:text-gray-300 hover:border-gray-300 border-gray-600"
                                      }
                                      `}
            onClick={async () => {
              toggleChats(
                windowinnerwidth -
                  (show_users ? config.chat.windowoffset.panelwidth : 0) -
                  (show_chats ? config.chat.windowoffset.panelwidth : 0),
                show_chats
              );
            }}
            onKeyDown={async (e) => {
              if ([13, 32].indexOf(e.keyCode) > -1) {
                toggleChats(
                  windowinnerwidth -
                    (show_users ? config.chat.windowoffset.panelwidth : 0) -
                    (show_chats ? config.chat.windowoffset.panelwidth : 0),
                  show_chats
                );
              }
            }}
          >
            <SvgJsx
              type={"fill"}
              icon={"menu-sm"}
              className={`h-5 w-5  `}
              title={"chat options"}
            />
            {isAdmin && report_queue > 0 && (
              <div className="absolute top-0 right-0 -mt-1 -mr-1">
                <span className="flex h-3 w-3 relative">
                  <span
                    className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${
                      showReminder ? "bg-white" : "bg-red-400"
                    }`}
                  ></span>
                  <span
                    className={`relative inline-flex rounded-full h-3 w-3  ${
                      showReminder ? "bg-white" : "bg-red-400"
                    }`}
                  ></span>
                </span>
              </div>
            )}
          </div>

          {/* HOST NAME */}

          <Link
            href={`/${profiledataRef?.current?.user_name}`}
            className={`flex-0 font-bold text-xl  truncate  max-w-[200px] sm:max-w-[300px]`}
            // onClick={closeModal}
            // onKeyDown={(e)=> {if ([13].indexOf(e.keyCode) > -1) closeModal()}}
            // role="button"
            // tabIndex={0}
            title={profiledataRef?.current?.user_name}
          >
            {/* AVATAR + NAME CONTAINER */}
            <div
              className={`flex-0 flex flex-none items-center content-center group
                                        rounded-md px-0.5 cursor-pointer
                                        `}
            >
              {/* AVATAR CONTAINER */}
              <div
                className={`flex-0 -mb-1 ${
                  showReminder ? "" : "mr-1"
                } flex items-center content-center `}
              >
                <AvatarFlairClean
                  isPlaying={profiledataRef?.current?.stream_status == "1"}
                  isContributor={profiledataRef?.current?.contributor_level > 0}
                  isBot={false}
                  avatar_url={profiledataRef?.current?.avatar_url}
                  avatar_url_sfw={profiledataRef?.current?.avatar_url_sfw}
                  bol_nsfw={profiledataRef?.current?.bol_nsfw}
                  avatar_crdate={profiledataRef?.current?.avatar_crdate}
                  id={profiledataRef?.current?.id}
                  user_name={profiledataRef?.current?.user_name}
                  bgClass="w-8 h-8"
                  containerClass="w-9 h-9 "
                  avatarClass="w-8 h-8 border-2 border-transparent "
                  rotaryContainerClass="w-8 h-8 mr-4"
                  rotaryInternalClass="w-8 h-8"
                  sizes={{
                    width: 50,
                    height: 50,
                    quality: 50,
                  }}
                  showReminder={showReminder}
                />
              </div>

              {/* USERNAME */}

              <div
                className={`${
                  showReminder ? "text-white" : " text-gray-200"
                } flex-0 font-bold  ml-1 mr-0.5 text-xs xs:text-sm truncate `}
              >
                {profiledataRef?.current?.user_name}
              </div>

              {!showReminder &&
                profiledataRef?.current?.contributor_level > 0 && (
                  <div className="text-xl font-bold -mb-1 ml-1">
                    <ProfileContributorTag
                      showReminder={showReminder}
                      size={16}
                    />
                  </div>
                )}
            </div>
          </Link>

          {/* {!showReminder && <div className="flex-1" />} */}

          <div
            className={`flex-1 flex items-center content-center justify-right xs:justify-center 
                                      ${showReminder ? "visible" : "invisible"}
                                    `}
          >
            <button
              className="flex-1 h-7 justify-right xs:justify-center p-0.5 text-right xs:text-center cursor-pointer mx-2 text-sm sm:text-xl font-bold rounded-md flex items-center content-center hover:opacity-80 "
              tabIndex={0}
              onClick={() =>
                startPlayer(
                  true,
                  profiledataRef?.current,
                  player_state,
                  set_player_state,
                  userdata,
                  channel_listen
                )
              }
              onKeyDown={(e) => {
                if ([13, 32].indexOf(e.keyCode) > -1)
                  startPlayer(
                    true,
                    profiledata,
                    player_state,
                    set_player_state,
                    userdata,
                    channel_listen
                  );
              }}
            >
              <SvgJsx
                type={"fill"}
                icon={"play-sm"}
                className={`h-8 w-8 sm:h-10 sm:w-10  `}
                title={"Play"}
              />

              <div className="ml-1 text-md sm:text-xl whitespace-nowrap truncate inline xs:flex items-center content-center">
                <div className="hidden xs:block">
                  <StopWatch
                    start_date={profiledataRef?.current?.stream_start_date}
                    timerRef={timerRef}
                  />
                </div>
              </div>
            </button>
          </div>

          {/* SPACER */}
          <div className="flex-0 sm:w-[128px]"></div>

          {!show_users && validTipJar(profiledata) && (
            <div>
              <ProfileMenuTipJarMobile
                profiledata={profiledata}
                preview={true}
                active={showReminder}
              />
            </div>
          )}

          <div>
            {/* TRIGGER USERS  */}
            <div
              role="button"
              tabIndex={0}
              title="Show Users"
              onClick={async () => {
                toggleUsers(
                  windowinnerwidth -
                    (show_users ? config.chat.windowoffset.panelwidth : 0) -
                    (show_chats ? config.chat.windowoffset.panelwidth : 0),
                  show_users
                );
              }}
              onKeyDown={async (e) => {
                if ([13, 32].indexOf(e.keyCode) > -1) {
                  toggleUsers(
                    windowinnerwidth -
                      (show_users ? config.chat.windowoffset.panelwidth : 0) -
                      (show_chats ? config.chat.windowoffset.panelwidth : 0),
                    show_users
                  );
                }
              }}
              className={`flex cursor-pointer  border text-xs font-bold p-1.5 rounded-md  items-center content-center
                                          ${
                                            showReminder
                                              ? show_users
                                                ? " bg-red-700 text-white border border-red-700 hover:border-white "
                                                : " text-white border-red-700 hover:border-white hover:bg-red-500 "
                                              : show_users
                                              ? " text-white  bg-gray-600   hover:bg-gray-400 border-gray-600"
                                              : "   text-gray-400   hover:text-gray-300 hover:border-gray-300 border-gray-600"
                                          }
                                          `}
            >
              <div className="flex-0">
                <SvgJsx
                  type={"fill"}
                  icon={"user-group-sm"}
                  className={`h-5 w-5  `}
                  title={"user list"}
                />
              </div>
              <div className="flex-0 ml-1">
                {hasData(profiledata?.current_listeners)
                  ? profiledata?.current_listeners > members_chat.length
                    ? calculateUsers(
                        profiledata?.current_listeners,
                        profiledata
                      ) //todo - swap this with websockets when ready
                    : calculateUsers(members_chat.length, profiledata)
                  : calculateUsers(members_chat.length, profiledata)}
              </div>
            </div>
          </div>

          {/* <div className='flex-0 flex items-center content-center'>
                                        <button
                                            type="button"
                                            tabIndex={0}
                                            className={` ml-2 ${showReminder 
                                              ? " text-red-300 hover:text-white "
                                              : "  text-gray-400   hover:text-gray-300 hover:border-gray-300 border-gray-600" }
                                            }`}
                                            onClick={closeModal}
                                            onKeyDown={(e)=> {if ([13].indexOf(e.keyCode) > -1) closeModal()}}
                                        >
                                            <SvgJsx 
                                              type={'fill'}
                                              icon={"x-sm"}
                                              className={`h-5 w-5  `}
                                              title={"Close"}
                                            />
                                        </button>
                                    </div> */}
        </div>

        <div
          className={` z-50 w-full flex
                      top-[208px
                        bg-gray-900
                        `}
        >
          {/* Chats */}
          <div
            className={`${
              show_chats ? "w-48 visible" : "w-0 invisible"
            } transition  transition-slowest ease duration-100 flex 

            fixed sm:static z-[10000] top-[49px]  rounded-bl-md shadow-md  borderborder-gray-700
                                      bg-gray-800`}
          >
            {chatlists}
          </div>
          <div
            className={`${
              show_chats ? "w-full visible" : "w-0 invisible"
            } cursor-pointer fixed sm:hidden z-[100] top-[49px] right-[0px] bg-[#000000aa] w-full`}
            style={{
              height: windowinnerheight + 48,
              MozBoxShadow: `inset 0 -20px 20px -20px rgba(0,0,0,0.1)`,
              WebkitBoxShadow: `inset 0 -20px 20px -20px rgba(0,0,0,0.1)`,
              boxShadow: `inset 0 -20px 20px -20px rgba(0,0,0,0.1)`,
            }}
            onClick={() => {
              set_show_chats(false);
            }}
          ></div>

          {/* MESSAGE AREA */}
          <div className="flex-1">
            {/* CHAT MESSAGES */}
            <div
              ref={refscroll}
              className={` w-full  border shadow-inner 
        border-gray-700
        overflow-y-scroll overflow-x-auto
        ${
          chatdata?.length == 0
            ? "flex justify-center items-center content-center"
            : ""
        }
      `}
              style={{
                height: windowinnerheight,
                MozBoxShadow: `inset 0 -20px 20px -20px rgba(0,0,0,0.1)`,
                WebkitBoxShadow: `inset 0 -20px 20px -20px rgba(0,0,0,0.1)`,
                boxShadow: `inset 0 -20px 20px -20px rgba(0,0,0,0.1)`,
              }}
            >
              {/* NO MESSAGES */}
              {chatarraydataisLoading && chatdata?.length == 0 && (
                <div className="flex-1 mx-auto justify-center flex  ">
                  <Loading
                    showRandom={false}
                    message={loading_message}
                    classSize={`w-20 h-20 mx-auto block`}
                    classAnimate="animate-spin-slow"
                    bgColor="#00000000"
                    centerColor="#000000"
                    pointerColor="#c0c0c0"
                    holeColor="#000000aa"
                    dialerColor="#c0c0c0aa"
                    size="64"
                  />
                </div>
              )}

              {!chatarraydataisLoading && chatdata?.length == 0 && (
                <div className=" text-center p-4 ">{nodata}</div>
              )}

              {/* CHAT IS POPULATED */}
              {chatdata?.length > 0 &&
                chatdata.map((msg_object, index) => {
                  return (
                    <Message
                      key={msg_object?.chat_id}
                      type={msg_object?.type}
                      index={index}
                      chat={msg_object}
                      profiledata={profiledata}
                      userdata={userdata}
                      isAdmin={isAdmin}
                      show_header={
                        index == 0 ||
                        //|| ["2","3"].indexOf(msg_object?.emoji?.toString()) > -1 //stickers & uploads
                        chatdata[Math.max(index - 1, 0)]?.int_utc_timestamp <=
                          msg_object?.int_utc_timestamp -
                            config.chat.messageheaderinterval || //time gap on the same chatter
                        chatdata[Math.max(index - 1, 0)]?.id?.toString() !==
                          msg_object?.id?.toString() || //new chatter
                        chatdata[
                          Math.max(index - 1, 0)
                        ]?.bol_private?.toString() !==
                          msg_object?.bol_private?.toString() || //private messages
                        ["bot", "server"].indexOf(
                          chatdata[Math.max(index - 1, 0)]?.type?.toString()
                        ) > -1
                      }
                      show_footer={
                        // index==chatdata?.length-1 //this is covered by the bottomRef div below
                        // ["2","3"].indexOf(chatdata[Math.min(index+1,chatdata?.length-1)]?.emoji?.toString()) > -1 //stickers & uploads
                        chatdata[Math.min(index + 1, chatdata?.length - 1)]
                          ?.int_utc_timestamp -
                          config.chat.messageheaderinterval >
                          msg_object?.int_utc_timestamp || //time gap on the same chatter
                        chatdata[
                          Math.min(index + 1, chatdata?.length - 1)
                        ]?.id?.toString() !== msg_object?.id?.toString() || //new chatter
                        chatdata[
                          Math.min(index + 1, chatdata?.length - 1)
                        ]?.bol_private?.toString() !==
                          msg_object?.bol_private?.toString() || //private messages
                        ["bot", "server"].indexOf(
                          chatdata[
                            Math.min(index + 1, chatdata?.length - 1)
                          ]?.type?.toString()
                        ) > -1
                      }
                      openModal={openModal}
                      openModalChooser={openModalChooser}
                      closeModal={closeChatModal}
                      CloseButton={CloseButton}
                      msg={msg}
                      set_msg={set_msg}
                      session={session}
                      chat_emoji={profiledata?.chat_emoji}
                      inputRef={inputRef}
                      position={
                        index >= chatdata?.length - 5
                          ? "left bottom"
                          : "left top"
                      }
                      time={time}
                      chatWidth={
                        windowinnerwidth -
                        (show_users ? config.chat.windowoffset.panelwidth : 0) -
                        (show_chats ? config.chat.windowoffset.panelwidth : 0)
                      } //{chat_width}
                      set_commenting={set_commenting}
                      server_website={ssr_data_tribe?.server_website}
                    />
                  );
                })}

              <div
                ref={bottomRef}
                className={` h-3 block 
                      ${
                        chatdata[
                          chatdata?.length - 1
                        ]?.bol_private?.toString() == "1"
                          ? ` border-red-200 bg-[#330505] text-white `
                          : ` bg-gray-900 text-white`
                      }
                    `}
              >
                <></>
              </div>

              <div
                ref={scrollRef}
                className={`h-[1px] block 
                       ${
                         chatdata[
                           chatdata?.length - 1
                         ]?.bol_private?.toString() == "1"
                           ? ` border-red-200 bg-[#330505] text-white `
                           : ` bg-gray-900 text-white`
                       }
                        `}
              >
                <></>
              </div>
            </div>

            {/* CHAT IS NOT IN ALL/DM MODE */}
            {["all", "dm"].indexOf(filterRef.current) == -1 && (
              <div className=" w-full">
                <div
                  className={`border-t p-2 font-bold text-center cursor-pointer animate-pulse
                              
                              bg-blue-800 text-blue-200 border-gray-700
                              h-12  flex items-center content-center justify-center
                              `}
                  onClick={() => {
                    set_filter("all");
                    set_extra("all", profiledata?.user_name);
                  }}
                >
                  Return to main chat
                </div>
              </div>
            )}

            {/* CHAT IS DISABLED */}
            {!isAdmin &&
              (profiledata?.chat_permissions_who == "0" || //completely disabled
                (profiledata?.chat_permissions_when == "2" && //only on while stream is active
                  profiledata?.stream_status == "0")) && (
                <div className=" w-full">
                  <div
                    className={`border-t p-2 font-bold text-center
                              bg-red-800 text-red-200 border-gray-700
                              h-12  flex items-center content-center justify-center
                              `}
                  >
                    {profiledata?.user_name} has set chat to be read-only.
                  </div>
                </div>
              )}

            {/* CHAT IS DISABLED */}
            {!isAdmin &&
              profiledata?.chat_permissions_who == "3" && //completely disabled
              !isApproved && (
                <div className=" w-full">
                  <div
                    className={`border-t p-2 font-bold text-center
                              bg-red-800 text-red-200 border-gray-700
                              h-12  flex items-center content-center justify-center
                              `}
                  >
                    Only approved users can chat (marked with{" "}
                    <SvgJsx
                      type={"outline"}
                      icon={"check"}
                      className={` h-4 w-4 text-white opacity-70 inline-block`}
                      title={"Approved chatter"}
                    />
                    )
                  </div>
                </div>
              )}

            {/* CHAT IS BLOCKED */}
            {isUserBlocked && (
              <div className=" w-full">
                <div
                  className={`border-t p-2 font-bold text-center
                              bg-red-800 text-red-200 border-gray-700
                              h-12  flex items-center content-center justify-center
                              `}
                >
                  You do not have permission to post in chat.
                  {isBlockedRemaining(
                    profiledata?.block_json,
                    profiledata?.timestamp,
                    userdata?.id
                  )}
                </div>
              </div>
            )}

            {/* // CHAT IS ENABLED */}
            {!isUserBlocked &&
              ["all", "dm"].indexOf(filterRef.current) > -1 &&
              (isAdmin ||
                (profiledata?.chat_permissions_who == "1" &&
                  (profiledata?.chat_permissions_when == "1" ||
                    (profiledata?.chat_permissions_when == "2" &&
                      profiledata?.stream_status == "1"))) ||
                (profiledata?.chat_permissions_who == "3" &&
                  isApproved &&
                  (profiledata?.chat_permissions_when == "1" ||
                    (profiledata?.chat_permissions_when == "2" &&
                      profiledata?.stream_status == "1")))) && (
                <>
                  <div
                    className="w-full border-t z-50  relative
                                  border-gray-700 "
                  >
                    {((!isOnScreenRef.current && pause_chat) ||
                      (pause_chat && chatdata_pause.length > 0)) &&
                      !hidescrollbutton && (
                        <div
                          className={` ${
                            chatdata_pause?.length > 0 ? "animate-pulse" : ""
                          }
          absolute -mt-8 left-0 right-0 text-center transition ease-in-out  `}
                        >
                          <a
                            className="cursor-pointer text-sm px-4 py-1 rounded-full font-bold shadow-md
                          bg-red-500 hover:bg-red-400 text-white"
                            onClick={(e) => {
                              onChatResume();
                              //scrollToBottom(scrollRef,'smooth',0)
                            }}
                          >
                            {
                              chatdata_pause?.length == 0
                                ? `Bottom`
                                : chatdata_pause?.length <=
                                  config.chat.maxunseenmessages
                                ? `${followerFormat(
                                    chatdata_pause?.length,
                                    "new message",
                                    "new messages"
                                  )}`
                                : `lots of new messages` //${config.chat.maxunseenmessages}+
                            }
                          </a>
                        </div>
                      )}

                    <ChatForm
                      msg={msg}
                      set_msg={set_msg}
                      //handleKeyPress={(event)=> {if (event.charCode == 13 && !messageIsEmpty) sendmsg(event,channel_id,"message",msg,'');}}
                      handleFormSubmission={(event) => {
                        event.preventDefault();
                        if (
                          loading ||
                          !userdata ||
                          !session ||
                          userdata?.id?.toString() == "0"
                        ) {
                          signIn();
                          return;
                        }
                        //scrollToBottom(scrollRef,'smooth',0);
                        if (inputRef.current.value.length > 0) {
                          sendmsg(
                            event,
                            channel_id_chat_public,
                            "message",
                            { ...msg, text: inputRef.current.value },
                            ""
                          );
                          inputRef.current.value = "";
                          set_shadow_text("");

                          isSafari
                            ? inputRef.current.blur() //close the ios keyboard
                            : inputRef.current.focus();
                        }
                        set_commenting(false);
                      }}
                      session={session}
                      loading={loading}
                      userdata={userdata}
                      userdataloading={!userdata}
                      sendmsg={sendmsg}
                      channel_id={channel_id_chat_public}
                      profiledata={profiledata}
                      openModalChooser={openModalChooser}
                      inputRef={inputRef}
                      time={time}
                      show_chats={show_chats}
                      show_users={show_users}
                      commenting={commenting}
                      set_commenting={set_commenting}
                      placeholder_text={textProcess(
                        loading || !userdata
                          ? `Authorizing... hang on...`
                          : !session
                          ? `Login to post in chat...`
                          : msg?.bol_private == 1
                          ? `whisper privately to host or @user...`
                          : profiledata?.chat_permissions_when?.toString() ==
                              "2" &&
                            profiledata?.stream_status?.toString() !== "1"
                          ? `chat is restricted when the stream is offline`
                          : profiledata?.chat_permissions_who?.toString() == "3"
                          ? `chat is restricted to approved users`
                          : profiledata?.chat_permissions_who?.toString() == "0"
                          ? `chat is restricted to staff only`
                          : `say something to everyone...` //who == "1"
                      )}
                      shadow_text={shadow_text}
                      set_shadow_text={set_shadow_text}
                    />
                  </div>
                </>
              )}
          </div>

          {/* USER LIST */}
          {/* Mobile close */}

          <div
            className={`${
              show_users ? "w-48 visible" : "w-0 invisible"
            } transition  transition-slowest ease duration-100
     fixed sm:static z-[10000] top-[49px] right-[0px] rounded-bl-md shadow-md  border border-gray-700 
     bg-gray-800  rounded-br-md
    `}
          >
            <div
              className="border border-gray-700 w-full  overflow-y-auto overflow-x-hidden flex flex-col"
              style={{
                height: windowinnerheight + 48,
              }}
              ref={ref}
              onMouseDown={onMouseDown}
              onDragStart={onMouseDown}
              onDrag={onMouseDown}
            >
              <ChatUsersArrayVertical
                members_chatters={members_chat}
                members_listeners={members_listeners}
                sum_chatters={chatters.current}
                sum_chatters_guests={members_chat.length - chatters.current}
                sum_listeners={listeners.current}
                sum_listeners_guests={
                  members_listeners.length - listeners.current
                }
                openModal={openModal}
                chatusersarray_str={member_list(members_chat)}
                profiledata={profiledataRef.current}
                userdata={userdata}
                state_following_them={state_following_them}
                set_state_following_them={set_state_following_them}
                state_following_them_status={state_following_them_status}
                set_state_following_them_status={
                  set_state_following_them_status
                }
                session={session}
                signIn={signIn}
              />
            </div>
          </div>
        </div>
        <div
          className={`${
            show_users ? "w-full visible" : "w-0 invisible"
          } cursor-pointer fixed sm:hidden z-[100] top-[49px] left-[0px] bg-[#000000aa] w-full`}
          style={{
            height: windowinnerheight + 48,
            MozBoxShadow: `inset 0 -20px 20px -20px rgba(0,0,0,0.1)`,
            WebkitBoxShadow: `inset 0 -20px 20px -20px rgba(0,0,0,0.1)`,
            boxShadow: `inset 0 -20px 20px -20px rgba(0,0,0,0.1)`,
          }}
          onClick={() => {
            set_show_users(false);
          }}
        ></div>
      </div>
      <ChatModal
        isChatOpen={isChatOpen}
        closeModal={closeChatModal}
        CloseButton={CloseButton}
        modal_panel={modal_panel}
        set_modal_panel={set_modal_panel}
        profiledata={profiledata}
        userdata={userdata}
        openModalChooser={openModalChooser}
        msg={msg}
        isAdmin={isAdmin}
        navtype={navtype}
        helptype={helptype}
        nodata={nodata}
        members_chat={members_chat}
        members_listeners={members_listeners}
        modal_length={modal_length}
        chatsingleuserdata={chatsingleuserdata}
        chatsingleuserdataisLoading={chatsingleuserdataisLoading}
        chatsingleuserdataisError={chatsingleuserdataisError}
        chatmultipleuserdata={chatmultipleuserdata}
        chatmultipleuserdataisLoading={chatmultipleuserdataisLoading}
        chatmultipleuserdataisError={chatmultipleuserdataisError}
        sendmsg={sendmsg}
        channel_id={channel_id_chat_public}
        router={router}
        state_following_them={state_following_them}
        set_state_following_them={set_state_following_them}
        state_following_them_status={state_following_them_status}
        set_state_following_them_status={set_state_following_them_status}
        session={session}
        changes={changes}
        set_changes={set_changes}
      />
    </>
  );
};

export default ProfileChatDynamic;
