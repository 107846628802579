import ProfileSingle from "./profile-single";
import ProfileSinglePlayReminder from "./profile-single-play-reminder";
import { startPlayer } from "@/lib/utils/startPlayer";
import { ModalContext } from "@/components/context/modal-wrapper";
import { useContext } from "react";

function ProfileSingleHeader({
  user_name,
  owner_block_json,
  userdata,
  showReminder,
  set_showReminder,
  profiledata_active,
  set_profiledata_active,
  stream_url_active,
  player_state,
  set_player_state,
  owner_id, //user_id
  owner_bol_staff_help, //bol_staff_help
  owner_staff_json, //staff_json
  owner_bol_admin_help, //bol_admin_help
  navtype,
  helptype,
}) {
  const { isOpen, set_isOpen } = useContext(ModalContext);

  return (
    <>
      {/* NEW CONTAINER */}
      {!showReminder && isOpen !== "chat" && (
        <div className="mx-auto max-w-7xl border-x border-b  bg-gray-900  border-gray-700">
          <div className="mx-4">
            <ul
              className={`divide-y w-full 
             divide-gray-700
          `}
            >
              <ProfileSingle
                itemdata={profiledata_active}
                userdata={userdata}
                shrink={false}
                ownerdata={profiledata_active}
                owner_block_json={owner_block_json}
                owner_name={user_name}
                owner_id={owner_id}
                owner_bol_staff_help={owner_bol_staff_help}
                owner_staff_json={owner_staff_json}
                owner_bol_admin_help={owner_bol_admin_help}
                placeholder={false} //profiledataisLoading}
                navtype={navtype}
                helptype={helptype}
                startPlayer={startPlayer}
                isProfileHeader={true}
              />
            </ul>
          </div>
        </div>
      )}

      {/* PLAY ALERT */}
      {showReminder && isOpen !== "chat" && (
        <ProfileSinglePlayReminder
          itemdata={profiledata_active}
          stream_url_active={stream_url_active}
          player_state={player_state}
          set_player_state={set_player_state}
          set_showReminder={set_showReminder}
          startPlayer={startPlayer}
        />
      )}
    </>
  );
}

export default ProfileSingleHeader;
